html,
body,
#root {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

* {
  -webkit-tap-highlight-color: transparent;
  --epr-search-border-color: rgba(63, 144, 109, 0.5);
}

*,
:after,
:before {
  box-sizing: border-box;
}

body {
  &::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    width: 8px;
    height: 8px;
    padding: 4px;
    overflow: scroll;
    z-index: 999999;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 16px;
    border: 0px solid #fff;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #a0a0a5;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
  }
}

.fileUploadPreviewListScrollbar::-webkit-scrollbar{
  height: 5px;
}

.fileUploadPreviewListScrollbar::-webkit-scrollbar-thumb{
  background: rgba(0, 0, 0, 0.24);
}